<template>
  <div class="inteBox">
    <intNavheader activeNameCheck="fourth"></intNavheader>
    <div class="titleTopLine"></div>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <!-- <div :class="userInfo.isAuditDiagnose == 'True' ? 'titleTopCenterLeftBox' : 'titleTopCenterLeftBoxno'" @click="goDoctor">处方审核</div> -->
        <waitNumber :waitDataTotalNumber='waitDataTotal'></waitNumber>
      </div>
      <div class="titleTopCenterCenter">
        <div class="titleTopCenterCenterTop">
    
          <el-tabs class="Content1Title" v-model="activeName" @tab-click="handleClick">
                    <div class="flex-l" style="margin-top: 20px">
                <el-form :model="searchForm" :inline="true">
                  <el-form-item>
                    <el-date-picker v-model="searchForm.datepicker" type="daterange"  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px"> </el-date-picker>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="患者手机号/患者名称" clearable></el-input>
                  </el-form-item>
                   <el-form-item label="">
                      <el-select v-model="searchForm.state" placeholder="请选择" size="small">
                        <el-option
                          v-for="item in Searchoptions"
                          :key="item.state"
                          :label="item.label"
                          :value="item.state">
                        </el-option>
                      </el-select>
                   </el-form-item>
                  <el-form-item>
                    <el-button type="primary" size="small" @click="Search">搜索</el-button>
                  </el-form-item>
                </el-form>
              </div>
            <el-tab-pane label="我的审核" name="2">
              <div class="flex-l" style="margin-top: 20px">
                <!-- <el-form :model="searchForm" :inline="true">
                  <el-form-item>
                    <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px"> </el-date-picker>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="患者手机号/患者名称" clearable></el-input>
                  </el-form-item>
                   <el-form-item label="">
                      <el-select v-model="searchForm.state" placeholder="请选择" size="small">
                        <el-option
                          v-for="item in Searchoptions"
                          :key="item.state"
                          :label="item.label"
                          :value="item.state">
                        </el-option>
                      </el-select>
                   </el-form-item>
                  <el-form-item>
                    <el-button type="primary" size="small" @click="Search">搜索</el-button>
                  </el-form-item>
                </el-form> -->
              </div>
              <el-table :data="tableData" style="width: 100%" height="calc(100vh - 234px)">
                <el-table-column prop="patientName" label="姓名" align="center" width="120">
                  <template slot-scope="scope">
                    <div>{{ scope.row.patientName }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="手机号" align="center" width="120">
                  <template slot-scope="scope">
                    <div>{{ scope.row.phone }}</div>
                  </template>
                </el-table-column>
                
                <el-table-column prop="diseaseDiagnose" label="诊断" align="center">
                  <template slot-scope="scope">
                    <div>{{ scope.row.diseaseDiagnose }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="userName" label="提交医生" width="120">
                  <template slot-scope="scope">
                    <div>{{ scope.row.userName }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="userPhone" label="提交医生联系方式"  width="180" >
                  <template slot-scope="scope">
                    <div>{{ scope.row.userPhone }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="addTime" label="提交日期" align="center" width="150">
                  <template slot-scope="scope">
                    {{ scope.row.addTime | timefilters }}
                  </template>
                </el-table-column>
                <el-table-column prop="auditStatus" label="状态" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.auditStatus == -1" style="color: #ff1c1c">已拒绝</div>
                    <div v-if="scope.row.auditStatus == 0" style="color: #e6a23c">待审核</div>
                    <div v-if="scope.row.auditStatus == 1" style="color: #00afb5">审核通过</div>
                    <div v-if="scope.row.auditStatus == 2" style="color: #00afb5">待取药</div>
                    <div v-if="scope.row.auditStatus == 3" style="color: #cccccc">已完成</div>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button @click="goseeDoctor1(scope.row)" type="text" size="small">详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </el-tab-pane>
              <el-tab-pane label="我的提交" name="1">
              <div class="flex-l" style="margin-top: 20px">
              </div>
              <el-table :data="tableData" style="width: 100%" height="calc(100vh - 234px)">
                <el-table-column prop="patientName" label="姓名" align="center" width="120">
                  <template slot-scope="scope">
                    <div>{{ scope.row.patientName }}</div>
                  </template>
                </el-table-column>
                   <el-table-column prop="phone" label="手机号" align="center" width="120">
                  <template slot-scope="scope">
                    <div>{{ scope.row.phone }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="diseaseDiagnose" label="诊断" align="center">
                  <template slot-scope="scope">
                    <div>{{ scope.row.diseaseDiagnose }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="waitAuditDoctorName" label="审核医生" align="center" width="120">
                  <template slot-scope="scope">
                    <div>{{ scope.row.waitAuditDoctorName }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="diseaseDiagnose" label="审核医生联系方式" align="center" width="180">
                  <template slot-scope="scope">
                    <div>{{ scope.row.waitAuditDoctorPhone }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="addTime" label="提交日期" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.addTime | timefilters }}
                  </template>
                </el-table-column>
                <el-table-column prop="auditStatus" label="状态" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.auditStatus == -1" style="color: #ff1c1c">已拒绝</div>
                    <div v-if="scope.row.auditStatus == 0" style="color: #e6a23c">待审核</div>
                    <div v-if="scope.row.auditStatus == 1" style="color: #00afb5">审核通过</div>
                    <div v-if="scope.row.auditStatus == 2" style="color: #00afb5">待取药</div>
                    <div v-if="scope.row.auditStatus == 3" style="color: #cccccc">已完成</div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="100">
                  <template slot-scope="scope">
                    <el-button @click="goseeDoctor(scope.row)" type="text" size="small">详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="titleTopLine"></div>
  </div>
</template>
<script>
import intNavheader from "../../components/intNavheader";
import waitNumber from "../../components/waitNumber";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
export default {
  components: {
    intNavheader,
    waitNumber
  },
  data() {
    var drugpatient = new patientMain(this.TokenClient, this.Services.Drug);
    return {
      drugpatientDomain: drugpatient,
      activeName: "2",
      addtoNew: false,
      inputValue: "",
      inputValue2: "",
      num: "",
      patientForm: {
        age: "",
        value: "",
        name: "",
      },
      searchForm: {
        datepicker: "",
        keyWord: "",
        state:'-999'
      },
      Searchoptions:[{
         state: '-999',
        label: '全部'
      },
      {
         state: '0',
        label: '待审核'
      },
      {
         state: '1',
        label: '审核通过'
      },
      {
         state: '-1',
        label: '审核拒绝'
      }],
      userInfo: this.$store.state.localData.userInfo,
      tableData: [],
      RefusetableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      waitDataTotal:0
    };
  },
  mounted() {
    let activeName = JSON.parse(sessionStorage.getItem("activeName")) + "";
    if (activeName && activeName != "null") {
      sessionStorage.removeItem("activeName");
      this.activeName = activeName;
    }
    this.gettWaitData1()
    this.getList()
  },
  methods: {
    Search() {
        switch (this.activeName) {
        case "1":
          this.getData();
          break;
        case "2":
          this.gettWaitData();
          break;
      }
    },
    gettWaitData1() {
      let _this = this;
      _this.waitDataTotal = 0;
      _this.drugpatientDomain.getMZIndex(
        function (data) {
          _this.waitDataTotal = data.data.waitAuditCount;
        },
        function (error) {
          _this.waitDataTotal = 0;
        }
      );
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    },
    getList() {
      switch (this.activeName) {
        case "1":
        this.searchForm = {
            datepicker: "",
            keyWord: "",
            state:'-999'
          };
          this.getData();
          break;
        case "2":
          this.searchForm = {
            datepicker: "",
            keyWord: "",
            state:'-999'
          };
          this.gettWaitData();
          break;
      }
    },
    handleCurrentChange(pageNum) {
      this.patientcurrentPage = pageNum;
      this.pageIndex = pageNum;
      this.getData();
    },
    RefuCurrentChange(pageNum) {
      this.RefucurrentPage = pageNum;
      this.RefusepageIndex = pageNum;
      this.gettWaitData();
    },
    goDoctor() {
      if (this.userInfo.isAuditDiagnose == "True") {
        this.$router.push({
          name: "PremangePatientList",
        });
      } else {
        this.$message({
          message: "暂无审方权限,请联系管理员添加",
          type: "warning",
        });
      }
      // this.doctorDialogVisible = true
      // this.GetAuditDoctorData()
    },
    handleClick(e) {
      this.pageIndex = 1;
      this.dataTotal = 0;
      this.searchForm = {
        datepicker: "",
        keyWord: "",
        state:'-999'
      };
      this.getList();
    },
    getData() {
      var _this = this;
      let state = _this.searchForm.state;
      let startTime = _this.searchForm.datepicker　? _this.searchForm.datepicker[0]: '%20'
      let endTime = _this.searchForm.datepicker ? _this.searchForm.datepicker[1] + ' 23:59:59': '%20'
      let keyWord = _this.searchForm.keyWord ? _this.searchForm.keyWord : '%20'
      _this.tableData = [];
      _this.drugpatientDomain.getRefusePrescription(
        state,
        startTime,
        endTime,
        keyWord,
        _this.pageIndex,
        function (data) {
          _this.tableData = data.data.results;
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (error) {
          _this.tableData = [];
        }
      );
    },
    gettWaitData() {
      var _this = this;
       let state = _this.searchForm.state;
      let startTime = _this.searchForm.datepicker ? _this.searchForm.datepicker[0] : '%20'
      let endTime = _this.searchForm.datepicker ? _this.searchForm.datepicker[1] + ' 23:59:59': '%20'
      let keyWord = _this.searchForm.keyWord ? _this.searchForm.keyWord : '%20'
      _this.tableData = [];
      _this.drugpatientDomain.getWaitAuditPrescription(
        state,
        startTime,
        endTime,
        keyWord,
        _this.pageIndex,
        function (data) {
          _this.tableData = data.data.results;
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (error) {
          _this.tableData = [];
        }
      );
    },
    goseeDoctor(item) {
      sessionStorage.setItem("activeName", "1");
      let isFalse = false;
      if (item.auditStatus == 0) {
        isFalse = true;
      } else {
        isFalse = false;
      }
      this.$router.push({
        name: "PremangeseeDtail",
        query: {
          id: item.patientDiagnoseId,
          type: "ziji",
          isFalse1: isFalse,
        },
      });
    },
    goseeDoctor1(item) {
      sessionStorage.setItem("activeName", "2");
      let isFalse = false;
      if (item.auditStatus == 0) {
        isFalse = true;
      } else {
        isFalse = false;
      }
      this.$router.push({
        name: "PremangeseeDtail",
        query: {
          id: item.patientDiagnoseId,
          isFalse1: isFalse,
        },
      });
    },
    goBack() {
      this.addtoNew = false;
    },
    gonewDoctor() {
      this.addtoNew = !this.addtoNew;
    },
    handleClickTop(e, e1) {
      if (e.label == "患者病历") {
        this.$router.push({
          name: "interrogationIndex",
        });
      }
      if (e.label == "患者列表") {
        this.$router.push({
          name: "intPatientList",
        });
      }
      if (e.label == "个人常用处方") {
        this.$router.push({
          name: "preintPatientList",
        });
      }
      if (e.label == "处方管理") {
        this.$router.push({
          name: "PremangePatientList",
        });
      }
    },
  },
};
</script>
<style scoped>
.pageBox {
  text-align: center;
  margin-top: 10px;
}
.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}
.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}
.titleTopLeftImg {
  width: 123px;
  height: 24px;
}
.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}
.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}
.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}
.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}
.titleTopRight {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.titleTopRight div {
  margin-left: 10px;
}
.titleTopLine {
  width: 100%;
  height: 12px;
}
.titleTopCenter {
  width: 100%;
  /* height: 100; */
  display: flex;
}
.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  /* max-height: 750px; */
  overflow: auto;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  /* margin-top: 0; */
}
.titleTopCenterCenter {
  width: 80%;
  border-radius: 6px;
  height: calc(100vh - 64px - 24px);
  overflow: auto;
}
.titleTopCenterCenterTop {
  background-color: #ffffff;
  padding: 0 40px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  height: 100%;
}
.flex-l {
  display: flex;
  align-items: center;
}
.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Content1Title {
  padding: 20px 0;
  padding-left: 0;
  font-size: 18px;
  height: calc(100% - 20px);
  /* font-weight: bold; */

  color: #333333;
  padding-bottom: 0;
  text-align: left;
}
.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}
.ageInput {
  width: 80%;
}
.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}
.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.table3Box {
  border: 1px solid #b9dcdd;
  border-radius: 6px;
  padding: 30px;
  margin-top: 10px;
}
.titleTopright1Line {
  border-bottom: 1px dashed #999999;
}
.titleTopright1 {
  font-size: 16px;
  color: #06add7;
}
.stepInput {
  width: 60%;
  margin-right: 10px;
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
::v-deep .el-pagination {
  padding: 10px 5px;
}
</style>
